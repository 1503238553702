@import '../../../../common/common';

.season-list {
  height: 100%;

  .header {
    box-sizing: border-box;
    display: flex;
    background: $color-grey-50;
    padding: 10px;
    border-radius: 20px 20px 0px 0px;

    .toggle-switch {
      flex: 2 1;
    }

    .actions {
      display: flex;
      flex: 1;
      justify-content: flex-end;

      button {
        color: $color-white;
        padding: 5px;
        margin-left: 0.5rem;
      }
    }
  }

  .body {
    background: $color-white;
    border-radius: 0px 0px 20px 20px;
    overflow: hidden;
    max-height: 50vh; // Set a maximum height
    height: auto;

    .items {
      @include primary-text;
      margin: 0;
      padding: 0;
      overflow-y: auto;
      list-style: none;
      height: 100%;
      max-height: inherit;

      .season-label {
        display: flex;
        justify-content: space-between;
        user-select: none;
        box-sizing: border-box;
        border-bottom: 2px solid hsl(0, 0%, 93%);
        padding: 0.6rem;
        cursor: pointer;
      }

      .name {
        display: flex;
        width: 45%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .add-icon {
          color: $color-green;
          margin-right: 0.5rem;
        }
      }
      .date {
        width: 35%;
      }
      .priority {
        width: 20%;
      }

      .hidden {
        display: none;
        &:checked + .season-label {
          border-left: 5px solid $color-green;
        }
      }
    }
  }
}
