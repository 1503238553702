@import '../../common/common';
@import '../../common/_buttons.scss';
@import '../../common/_variables.module.scss';

.events {
  width: 100%;
  padding: 25px;
  &__form {
    &__row {
      .label {
        @include primary-text;
        text-overflow: unset;
        padding-right: 15px;
      }
      .MuiInputLabel-root {
        @include primary-text;
        top: -5px;
        &.MuiInputLabel-shrink {
          top: 0;
        }
      }
      .MuiTextField-root {
        margin-top: 0;
      }
      .collapsible-list {
        display: flex;
        width: 100%;
        flex-direction: column;
        &-header {
          display: flex;
          .date-header {
            width: 30%;
          }
          .occupancy-header {
            width: 35%;
          }
          .forecast-header {
            width: 35%;
            display: flex;
            justify-content: space-evenly;
          }
        }
        &-body {
          max-height: 104px;
          overflow-y: auto;
        }
        // &-body::-webkit-scrollbar {
        //   display: none; /* Hide the default scrollbar in Webkit (Chrome, Safari, etc.) */
        // }
        &-item {
          display: flex;
          padding: 5px;
          align-items: center;
          justify-content: space-evenly;
          .date-container {
            width: 30%;
          }
          .occupancy-container {
            width: 35%;
            display: flex;
          }
          .forecast-container {
            width: 35%;
            display: flex;
            justify-content: space-evenly;
          }
        }
      }
      .sign {
        width: 60px;
        margin-right: 20px;
      }
      .magnitude {
        width: 80px;
        display: flex;
        align-items: center;
        .MuiOutlinedInput-root {
          width: initial;
        }
      }
      &-event-records {
        align-items: start;
      }
      &-event-record {
        width: 100%;
      }
      &-event-record-date {
        justify-content: space-between;
        padding: 0 0 10px 0;
        align-items: start;
      }
      &-item {
        .MuiCollapse-wrapper {
          position: absolute;
          bottom: 10px;
          left: 0;
          padding: 30px;
          box-sizing: border-box;
          width: 100%; /* adjust based on your desired width */
        }
        .MuiCollapse-root {
          transition-duration: 0ms !important;
        }
        .recurring {
          cursor: pointer;
          input[type='checkbox'] {
            display: block;
            color: $color-green;
          }
          label {
            margin-right: 10px;
            cursor: pointer;
          }
          .MuiButtonBase-root {
            color: $color-grey-main;
          }
        }
        .Mui-disabled {
          opacity: 0.6;
        }
      }

      &-item-event-name .MuiOutlinedInput-root {
        width: 18rem !important;
      }

      &-item-label-sm {
        font-size: 11px;
      }

      &-item-remove {
        width: auto;
      }

      &-item-percentile {
        align-self: center;
      }

      display: flex;
      align-items: flex-start;
      padding: 10px 0px 10px 5px;

      .MuiInputBase-input {
        padding: 8px 14px !important;
      }

      .MuiInputBase-root {
        @include primary-text;
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
      &-item-event-type .MuiOutlinedInput-root {
        margin: 0;
        width: 200px;
        border-radius: 10px !important;
      }
    }

    &-save-btn {
      @include button-success;
      margin: 5px;
      &:disabled {
        @include button-disable;
      }
    }
    &-add-date-btn {
      color: $color-brown !important;
    }
    &-delete-btn {
      @include button-warning;
      margin: 5px;
      &:disabled {
        @include button-disable;
      }
    }
    &-remove-date-btn {
      color: $color-warning !important;
    }
    &-footer {
      display: flex;
      justify-content: flex-end;
    }
  }
}
