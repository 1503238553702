@import '../../common/common';

.dashboard {
  position: relative;
  padding-top: 1rem;

  .grid-wrapper {
    height: calc(100vh - 275px);
    min-height: 39rem;
    width: 100%;
  }

  .ag-theme-alpine.sform-vis {
    height: calc(100vh - 500px);
    min-height: 25rem;
  }

  .ag-theme-alpine.askform-vis {
    height: calc(100vh - 400px);
    min-height: 25rem;
  }

  /**** ag-grid css ***/
  .ag-root-wrapper {
    border-radius: 12px;
    font-family: $font-family-main;
    text-align: center;
  }
  .ag-theme-alpine .ag-header {
    background-color: $color-grey-65;
  }
  .ag-header-cell-moving {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .ag-theme-alpine
    .ag-ltr
    .ag-has-focus
    .ag-cell-focus:not(.ag-cell-range-selected) {
    border-color: none;
    border: none;
  }
  .ag-theme-alpine .ag-header-row {
    color: $color-white;
    font-weight: $font-weight--regular;
    font-size: 15px;
    white-space: break-spaces;
  }
  .ag-header-cell-text {
    white-space: unset;
  }

  .ag-theme-alpine .ag-header-icon {
    display: inline-block;
    width: 100px;
  }

  .ag-theme-alpine .ag-row-odd {
    background-color: $color-white;
  }

  .ag-theme-alpine .ag-row-selected,
  .ag-pinned-left-cols-container .ag-row-hover,
  .ag-center-cols-clipper .ag-row-hover {
    background-color: $color-grey-6;
  }

  .ag-header-cell-comp-wrapper {
    justify-content: center;
  }

  .ag-tooltip {
    font-weight: normal;
  }

  .ag-theme-alpine .ag-cell {
    display: inline-flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    line-height: normal;
    padding: 0;
  }

  .ag-theme-alpine {
    --ag-selected-row-background-color: #f4f4f4;
  }

  .ag-pinned-left-cols-container .ag-row-selected::after {
    position: absolute;
    top: 0;
    left: 0px;
    width: 8px;
    height: 100%;
    content: '';
    background-color: $color-green;
  }

  /* Custom Header css (for icons)*/

  .sortDownArrow {
    position: absolute;
    right: 8px;
    top: 42%;
  }

  .sortUprrow {
    position: absolute;
    right: 8px;
    top: 42%;
  }

  .customHeader {
    width: 100%;
    height: 100%;
    align-content: center;
  }

  .customHeader,
  .customHeaderLabel {
    span {
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .icon-added {
      color: $color-green;
    }
  }

  .hideButton {
    display: none;
  }

  .showButton {
    display: inline-block;
  }

  .ag-theme-alpine .ag-header-cell {
    padding-right: 0;
    padding-left: 0;
  }

  .ag-overlay-wrapper.ag-layout-normal.ag-overlay-no-rows-wrapper {
    display: none;
  }
  .ag-center-cols-container {
    display: block;
    min-width: 100% !important;
  }

  .age-border {
    border-right: 1px solid $color-grey-divider !important;
  }
  .ag-theme-alpine .ag-pinned-left-header,
  .ag-theme-alpine .ag-pinned-right-header {
    border: none;
  }
  .ag-theme-alpine
    .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(
      .ag-cell-range-single-cell
    ) {
    border-right: none;
  }
  .ag-horizontal-left-spacer {
    overflow: hidden;
  }

  .row-status-review {
    background-color: #f4f4f4;
  }

  /************ end ag-grid css *************/

  &__header {
    padding: 0 20px;
  }

  &__options_wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__options {
    display: flex;
    align-items: center;

    .Mui-disabled {
      opacity: 0.6;
    }
  }

  &__options-btn {
    @include button-no-style;
    padding-right: 12px;

    &.MuiButtonBase-root.MuiIconButton-root {
      color: $color-brown;
      svg {
        height: 22px;
        width: 22px;
      }
      &.export-btn {
        color: $color-green;
      }
    }

    a {
      color: inherit;
    }
  }

  &__row-selected-text {
    color: $color-green;
  }

  &__tabs {
    padding-right: 25px;
    display: flex;
    align-items: center;
    :last-child {
      border: none;
    }
  }

  &__tab {
    border-right: 1px solid $color-grey;
    padding: 0 14px;

    a {
      text-decoration: none;
      color: $color-dark-grey;
    }
  }

  &__tab-activeTab {
    a {
      color: $color-brown;
      font-weight: $font-weight--bold;
    }
  }

  &__tab-droppable {
    border: 2px solid $color-green;
    border-radius: 10px;
  }

  &__tab-drop-target {
    background-color: rgba(0, 255, 0, 0.2);
  }

  &__options-more {
    display: flex;
    align-items: center;
  }
  .dropdownList__container {
    .dropdownList__button {
      color: $color-brown !important;
    }
  }
  &__rate-upload-input {
    margin: 0px 12px;
    width: 100%;
    background: rgba(255, 212, 100, 0.3);
    border-radius: 10px;
    border: none;
    padding: 5px 0;
    text-align: center;
  }
  &__pagination-container {
    position: absolute;
    right: 24px;
    bottom: 1rem;
    width: 2rem;
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__pagination-wrapper {
    display: flex;
    border: 1px solid $color-brown;
    border-radius: 20px;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: $color-white;

    .Mui-disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  &__pagination-row {
    .MuiButton-root {
      background-color: transparent;
      padding: 0;
      box-shadow: none;
      min-width: auto;
    }
    .MuiButton-root:hover {
      background-color: transparent;
      box-shadow: none;
    }
  }
  &__back-to-top {
    margin-top: 2px;
    background-color: $color-brown;
    .Mui-disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .liveCell__star {
    display: inline-block;
    color: $color-green;
    font-size: 18px;
    position: absolute;
    top: 11px;
  }
  .liveCell__divider,
  .nonLiveCell__divider {
    padding-left: 2px;
    margin-left: 3px;
    margin-right: 3px;
  }

  .liveCell__value {
    margin-right: 5px;
  }

  .comp-rate-cell {
    .inc {
      color: $color-green;
    }
    .dec {
      color: $color-warning;
    }
    .prev-rate-change {
      margin-right: 2px;
      svg {
        font-size: 0.75rem;
        display: inline-block;
      }
    }
    .days-from-update {
      color: $color-orange;
    }
  }

  .customHeader__name,
  .customHeader__column-icon {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }

  .active {
    color: #ffffff;
  }

  &__search-list-item {
    display: flex !important;
    justify-content: space-between !important;
  }
}
